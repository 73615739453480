import React from 'react';
import { EmailLink } from './components/EmailLink';

export function Privacy() {
  return (
    <div>
      <h1>Privacy Policy</h1>

      <p>This policy applies to all information collected or submitted on HabitPanda's website and our iPhone app.</p>

      <h2>Information we collect</h2>

      <p>This application does not collect or transmit any user's personally identifiable information. No personal information is used, stored, secured, or disclosed by services this application works with.</p>

      <h3>Technical basics</h3>

      <p>No technical information is sent or stored for now (such as IP addresses included in the HTTP calls the application makes). But we reserve the right to collect such informations in later versions to provide better service to you. We'll update this privacy policy then.</p>

      <h2>California Online Privacy Protection Act Compliance</h2>

      <p>We are in compliance with the requirements of the California Online Privacy Protection Act (CalOPPA) since we do not collect personal data.</p>

      <h2>Children's Online Privacy Protection Act Compliance</h2>

      <p>We are in compliance with the requirements of COPPA (Children's Online Privacy Protection Act) since we do not collect personal data.</p>

      <h2>Information for European Union Customers</h2>

      <p>If you are from the European Economic Area (EEA), we do not collect personal data and we are in compliance with the requirements of the GDPR.</p>

      <h2>Your Consent</h2>

      <p>By using our site or apps, you consent to our privacy policy.</p>

      <h2>Contacting Us</h2>

      <p>If you have questions regarding this privacy policy, you may email <EmailLink email="habitpanda.app@gmail.com" />.</p>

      <h2>Changes to this policy</h2>

      <p>If we decide to change our privacy policy, we will post those changes on this page. Summary of changes so far:</p>

      <ul>
        <li><strong>June 15, 2019:</strong> First published.</li>
      </ul>
    </div>
  );
}
