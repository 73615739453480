import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export function Home() {
  return (
    <div>
      <div className="row">
        <div className="col-sm-6">
          <h2>Track and build habits!</h2>
          <p>Add any habits you would like to build and how often you aim to perform them.</p>
          <p>Check in when completing a habit activity and track your progress on the home grid.</p>
          <p>View insights at a glance to provide details into how well you are doing.</p>
          <p>Add customizable reminders for each habit for increased awareness!</p>

          <a className="appstore-link" href="https://itunes.apple.com/app/apple-store/id1466306659">
            <img src="assets/appstore.svg" alt="Download on the app store" />
          </a>
        </div>
        <div className="col-sm-6">
          <div className="home-carousel">
            <Carousel showThumbs={false} showStatus={false} infiniteLoop={true}>
              <div>
                <img src="assets/screenshot1.png" alt="Screenshot 1" />
              </div>
              <div>
                <img src="assets/screenshot2.png" alt="Screenshot 2" />
              </div>
              <div>
                <img src="assets/screenshot3.png" alt="Screenshot 3" />
              </div>
              <div>
                <img src="assets/screenshot4.png" alt="Screenshot 4" />
              </div>
              <div>
                <img src="assets/screenshot5.png" alt="Screenshot 5" />
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}
