import React from 'react';
import { Navbar } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './App.css';
import { Privacy } from './Privacy';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as LogoText } from './logo-text.svg';
import { Home } from './Home';
import { EmailLink } from './components/EmailLink';

function App() {
  return (
    <Router>
      <header>
        <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
          <div className="container">
            <Navbar.Brand as={Link} to="/">
              <Logo height="2.75rem" width="2.75rem" className="app-logo" alt="Logo" />
              <LogoText height="1.75rem" width="7rem" className="app-logo-text" alt="HabitPanda" />
            </Navbar.Brand>
          </div>
        </Navbar>
      </header>

      <main className="container">
        <Route path="/" exact component={Home} />
        <Route path="/privacy/" component={Privacy} />
      </main>

      <footer>
        <div className="container">
          <p>&copy; {(new Date().getFullYear())} HabitPanda &middot; <EmailLink email="habitpanda.app@gmail.com">Contact</EmailLink> &middot; <Link to="/privacy">Privacy</Link></p>
        </div>
      </footer>
    </Router>
  );
}

export default App;
